import "../styles/globals.scss";
import "../styles/main.css";
import "./../config/config.styles.scss";
import React from "react";
import dynamic from "next/dynamic";

const Toaster = dynamic(() => import("react-hot-toast").then(c => c.Toaster), {
  ssr: false,
});

function MyApp({ Component, pageProps, err }) {
  const getLayout = Component.getLayout || (page => page);
  return (
    <>
      {getLayout(<Component {...pageProps} err={err} />)}
      <Toaster position="bottom-center" />
    </>
  );
}

export default MyApp;
