var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2385283a19b22571a896fdc0a43f71ad65c537cd"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  // tracesSampleRate: process.env.NEXT_PUBLIC_LOCALHOST ? 0.0 : 0.4,
  environment:
    process.env.NEXT_PUBLIC_ENV !== "development"
      ? "production"
      : "development",
  enabled: false, //process.env.NEXT_PUBLIC_LOCALHOST ? false : true,
  // replaysSessionSampleRate: 0.1,
  // // If the entire session is not sampled, use the below sample rate to sample
  // // sessions when an error occurs.
  // replaysOnErrorSampleRate: 0.1,
  // integrations: [
  //   Sentry.replayIntegration({
  //     // Additional SDK configuration goes in here, for example:
  //     maskAllText: false,
  //     blockAllMedia: true,
  //   }),
  // ],

  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
